import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setMessage } from './alertMessageSlice';

import TopicService from '../services/topic.service';
import { channelManipulation, channelReviewManipulation } from '../utils/channelCreation';

export const topicPreview = createAsyncThunk(
  'topicPreview',
  async (body, thunkAPI) => {
    try {
      const topic_post_data = {
        topic_title: body?.title,
        description: body?.description || '',
        languages: body?.languages || ['english'],
        time_frame: body?.timeFrame || 'last 1 month',
        keywords_string: body?.keywords,
        exclusions_string: body?.exclusions || '',
        exclusions_noise: {
          select_all: false,
          sweep_stakes: false,
          sales_listings: false,
          coupons: false,
          supplemental_income: false,
          cryptocurrency: false,
        },
        channel_name: body?.channels || [],
        location_origin: body?.location || '',
        location_origin_radius: body?.locationRadius || '0',
        post_country: body?.postCountry || [],
        user_country: '',
        include_sensitive_content: body?.include_sensitive_content || false,
        only_posts_with_media: body?.only_posts_with_media || false,
        fetch_reposts: body?.fetch_reposts || false,
        only_posts_from_verified_users:
          body?.only_posts_from_verified_users || false,
      };

      const brandUser = JSON.parse(localStorage.getItem('brandUser'));
      const fetch_uuid_for_topicPreview = await TopicService.fetch_uuid_for_topicPreview(
        topic_post_data,
        brandUser?.tenant_id,
        brandUser?.user_details.user_id
      );
      localStorage.setItem(
        'fetch_uuid_for_topicPreview',
        JSON.stringify(fetch_uuid_for_topicPreview)
      );
      localStorage.setItem('topicPreviewLoading', 'true');


      let actualResponse;
      let counter = 0;
      const refreshPreviewData = async () => {
        // if (counter === 30) {
        //   localStorage.removeItem('fetch_uuid_for_topicPreview');
        //   localStorage.removeItem('topicPreviewLoading');
        //   return { data: 'No data' };
        // }
        // counter += 1;
        actualResponse = await TopicService.topicPreview(
          fetch_uuid_for_topicPreview?.preview_uuid,
          // counter,
          brandUser?.tenant_id,
          brandUser?.user_details.user_id
        );
        if (typeof actualResponse === 'object') {
          localStorage.removeItem('fetch_uuid_for_topicPreview');
          localStorage.removeItem('topicPreviewLoading');
          actualResponse.preview_uuid =
            fetch_uuid_for_topicPreview?.preview_uuid;
          actualResponse.inputData = topic_post_data;


          return actualResponse;
        } else {
          let status = actualResponse.split(" ");


          status.shift();

          // Join the remaining words back into a string
          let resultString = status.join(" ");


          if (resultString && resultString == 'still in progress') {

            await new Promise((resolve) => setTimeout(resolve, 8000));
            return refreshPreviewData();
          } else if (resultString && resultString == 'has failed') {

            localStorage.removeItem('fetch_uuid_for_topicPreview');
            localStorage.removeItem('topicPreviewLoading');

            return { data: 'Has Failed' };
          }
        }
      };
      return await refreshPreviewData();
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);



export const reviewsForTopic = createAsyncThunk(
  'reviewsForTopic',
  async (preview_uuid, thunkAPI) => {
    try {
      const brandUser = JSON.parse(localStorage.getItem('brandUser'));
      const data = await TopicService.reviewsForTopic(
        brandUser?.tenant_id,
        brandUser?.user_details.user_id,
        preview_uuid
      );
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const reviewsFilterTopic = createAsyncThunk(
  'reviewsFilterTopic',
  async ({ dates, job_id }, thunkAPI) => {
    try {
      const brandUser = JSON.parse(localStorage.getItem('brandUser'));
      const data = await TopicService.reviewsFilterTopic(
        brandUser?.tenant_id,
        job_id,
        dates
      );
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const reviewsFilterTopicPreview = createAsyncThunk(
  'reviewsFilterTopicPreview',
  async ({ dates, preview_uuid }, thunkAPI) => {
    try {
      const brandUser = JSON.parse(localStorage.getItem('brandUser'));
      const data = await TopicService.reviewsFilterTopicPreview(
        brandUser?.tenant_id,
        preview_uuid,
        dates
      );
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const reviewsForCreatedTopic = createAsyncThunk(
  'reviewsForCreatedTopic',
  async (job_id, thunkAPI) => {
    try {
      const brandUser = JSON.parse(localStorage.getItem('brandUser'));
      const data = await TopicService.reviewsForCreatedTopic(
        brandUser?.tenant_id,
        job_id
      );
      let dataObj = channelReviewManipulation(data);
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const reviewsForDownload = createAsyncThunk(
  'reviewsForDownload',
  async (apiVal, thunkAPI) => {
    try {
      const brandUser = JSON.parse(localStorage.getItem('brandUser'));
      const data = await TopicService.reviewsForDownload(
        brandUser?.tenant_id,
        apiVal?.job_id,
        apiVal?.email_id
      );
      return { data };

    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
)

export const activeTopicList = createAsyncThunk(
  'activeTopicList',
  async (_, thunkAPI) => {
    try {
      const brandUser = JSON.parse(localStorage.getItem('brandUser'));
      const data = await TopicService.activeTopicList(
        brandUser?.tenant_id,
        brandUser?.user_details.user_id
      );
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const fetchCreatedTopic = createAsyncThunk(
  'fetchCreatedTopic',
  async (job_id, thunkAPI) => {
    try {
      const brandUser = JSON.parse(localStorage.getItem('brandUser'));
      const data = await TopicService.fetchCreatedTopic(
        brandUser?.tenant_id,
        job_id
      );

      // const dataObj = channelManipulation(data);
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const createNewTopic = createAsyncThunk(
  'createNewTopic',
  async (inputData, thunkAPI) => {
    try {
      const brandUser = JSON.parse(localStorage.getItem('brandUser'));
      const data = await TopicService.createNewTopic(
        brandUser?.tenant_id,
        brandUser?.user_details.user_id,
        inputData
      );
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const TopicHibernate = createAsyncThunk(
  'TopicHibernate',
  async (jobId, thunkAPI) => {
    try {
      const brandUser = JSON.parse(localStorage.getItem('brandUser'));
      const response = await TopicService.TopicHibernate(
        brandUser?.tenant_id,
        brandUser?.user_details.user_id,
        jobId
      );
      return { response };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const TopicDelete = createAsyncThunk(
  'TopicDelete',
  async (jobId, thunkAPI) => {
    try {
      const brandUser = JSON.parse(localStorage.getItem('brandUser'));
      const response = await TopicService.TopicDelete(
        brandUser?.tenant_id,
        brandUser?.user_details.user_id,
        jobId
      );
      return { response };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const TopicActive = createAsyncThunk(
  'TopicActive',
  async (jobId, thunkAPI) => {
    try {
      const brandUser = JSON.parse(localStorage.getItem('brandUser'));
      const response = await TopicService.TopicActive(
        brandUser?.tenant_id,
        brandUser?.user_details.user_id,
        jobId
      );
      return { response };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const FilterReportBasedOnDates = createAsyncThunk(
  'FilterReportBasedOnDates',
  async ({ startDate, endDate, job_id }, thunkAPI) => {
    try {
      const brandUser = JSON.parse(localStorage.getItem('brandUser'));
      const response = await TopicService.GetReportBasedOnDate(
        startDate,
        endDate,
        brandUser?.tenant_id,
        job_id
      );
      // let resObj = channelManipulation(response)
      return {
        response: response,
        // reviews: response?.reviews
      };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const QueryPromptApi = createAsyncThunk(
  'QueryPromptApi',
  async ({ prompt, channel_name }, thunkAPI) => {
    try {
      const brandUser = JSON.parse(localStorage.getItem('brandUser'));
      const response = await TopicService.GetQueryPromptData(
        prompt,
        channel_name,
      );
      return { response };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);


export const topicAIPreview = createAsyncThunk(
  'topicAIPreview',
  async (body, thunkAPI) => {
    try {
      const brandUser = JSON.parse(localStorage.getItem('brandUser'));
      const fetch_uuid_for_topicPreview = await TopicService.PreviewAiData(
        body
      );

      localStorage.setItem(
        'fetch_uuid_for_topicPreview',
        JSON.stringify(fetch_uuid_for_topicPreview)
      );
      localStorage.setItem('topicPreviewLoading', 'true');


      const topic_post_data = {


        topic_title: body?.title || '',
        description: body?.description || '',
        keywords_string: body?.keywords_string || '',
        channel_name: body?.channel_name || [],
        is_ai: true,
        prompt: body?.prompt || '',
        draft: body?.draft || '',
      };
      let actualResponse;
      let counter = 0;
      const refreshPreviewData = async () => {
        if (counter === 30) {
          localStorage.removeItem('fetch_uuid_for_topicPreview');
          localStorage.removeItem('topicPreviewLoading');
          return { data: 'No data' };
        }
        counter += 1;
        actualResponse = await TopicService.topicPreview(
          fetch_uuid_for_topicPreview?.preview_uuid,
          // counter,
          fetch_uuid_for_topicPreview?.tenant_id,
          fetch_uuid_for_topicPreview?.user_id
        );
        if (actualResponse != null && actualResponse != undefined && typeof actualResponse !== 'object') {

          const firstSpaceIndex = actualResponse?.indexOf(' ');
          const firstWord = actualResponse?.substring(0, firstSpaceIndex);
          const restOfString = actualResponse?.substring(firstSpaceIndex + 1);


          if (restOfString == 'still in progress') {

            await new Promise((resolve) => setTimeout(resolve, 12000));
            return refreshPreviewData();
          } else if (restOfString == 'has failed') {

            // thunkAPI.dispatch(setMessage(message));
            return { data: 'Has Failed' };
          }
        } else if (typeof actualResponse === 'object') {
          localStorage.removeItem('fetch_uuid_for_topicPreview');
          localStorage.removeItem('topicPreviewLoading');
          // actualResponse.preview_uuid =
          //   fetch_uuid_for_topicPreview?.preview_uuid;
          actualResponse.inputData = topic_post_data;


          return actualResponse;
        } else {
          await new Promise((resolve) => setTimeout(resolve, 12000));
          return refreshPreviewData();
        }
      };
      return await refreshPreviewData();

    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const createTopicSideBar = async (body) => {
  try {
    console.log(body, 'body');
  } catch (error) {
    return error.message;
  }
};

const initialState = {
  topicPreviewData: null,
  reviewsForTopicData: null,
  activeTopicListData: null,
  createdTopicData: [],
  filteredReport: null,
  queryValue: [],
  topicAIPreviewData: [],
  popUpActive: false
};

const topicSlice = createSlice({
  name: 'topicBuilder',
  initialState,
  reducers: {
    setPopUpActive: (state, action) => {
      state.popUpActive = action.payload;
    },

    setQueryPromt: (state, action) => {
      state.queryValue = action.payload;

    },
    topicPreviewEmpty: (state, action) => {
      state.topicPreviewData = null
    }

  },
  extraReducers: (builder) => {
    builder
      .addCase(topicPreview.fulfilled, (state, action) => {
        state.topicPreviewData = action.payload;
      })
      .addCase(topicPreview.rejected, (state, action) => {
        state.topicPreviewData = null;
      })
      .addCase(reviewsFilterTopic.fulfilled, (state, action) => {
        state.reviewsForTopicData = action.payload.data.reviews;
      })
      .addCase(reviewsFilterTopic.rejected, (state, action) => {
        state.reviewsForTopicData = action.payload.data.reviews;
      })
      
      .addCase(reviewsFilterTopicPreview.fulfilled, (state, action) => {
        state.reviewsForTopicData = action.payload.data.reviews;
      })
      .addCase(reviewsFilterTopicPreview.rejected, (state, action) => {
        state.reviewsForTopicData = action.payload.data.reviews;
      })
      .addCase(reviewsForTopic.fulfilled, (state, action) => {
        state.reviewsForTopicData = action.payload.data.reviews;
      })
      .addCase(reviewsForTopic.rejected, (state, action) => {
        state.reviewsForTopicData = null;
      })
      .addCase(reviewsForDownload.fulfilled, (state, action) => {
        state.reviewsForTopicData = action.payload.data.reviews;
      })
      .addCase(reviewsForDownload.rejected, (state, action) => {
        state.reviewsForTopicData = null;
      })
      .addCase(reviewsForCreatedTopic.fulfilled, (state, action) => {
        state.reviewsForTopicData = action.payload.data.reviews;
      })
      .addCase(reviewsForCreatedTopic.rejected, (state, action) => {
        state.reviewsForTopicData = null;
      })
      .addCase(activeTopicList.fulfilled, (state, action) => {
        state.activeTopicListData = action.payload.data;
      })
      .addCase(activeTopicList.rejected, (state, action) => {
        state.activeTopicListData = null;
      })

      .addCase(fetchCreatedTopic.fulfilled, (state, action) => {
       state.createdTopicData = [action.payload.data];
        // state.status = 'succeeded';
      })

      .addCase(fetchCreatedTopic.rejected, (state, action) => {
        console.log('Please Try Again');
      })
      .addCase(createNewTopic.fulfilled, (state, action) => {
        state.createdTopicData = [action.payload.data];
        
      })
      .addCase(createNewTopic.rejected, (state, action) => {
        console.log('Please Try Again');
      }).addCase(FilterReportBasedOnDates.rejected, (state, action) => {
        state.filteredReport = null;
      }).addCase(FilterReportBasedOnDates.fulfilled, (state, action) => {
        state.filteredReport = action.payload.data;
      })
      .addCase(QueryPromptApi.rejected, (state, action) => {
        state.queryValue = null;
      })
      .addCase(QueryPromptApi.fulfilled, (state, action) => {
        state.queryValue = action.payload.response;
      })
      .addCase(topicAIPreview.rejected, (state, action) => {
        state.topicPreviewData = null;
      })
      .addCase(topicAIPreview.fulfilled, (state, action) => {
        state.topicPreviewData = action.payload;
      })

  }
})

export const { setPopUpActive, setQueryPromt, topicPreviewEmpty } = topicSlice.actions;

export default topicSlice.reducer;
