import React, { useEffect, useState } from 'react';
import {
  Wrapper,
  HeaderWrapper,
  Span1,
  Span2,
  QueryPara,
  FlexWrapper,
  ChannelsText,
  TwitterText,
  StyledContainer,
  QueryPara2,
  TabHeading,
  Icon,
  FilterWrapper,
  DateWrapper,
  CreateTopic,
  DeletePopupWrapper,
  DivContainer,
  RadioContainer,
} from './ActiveTopic.styled';
// import SideBar from '../../components/SideBar/SideBar';
import {
  X_icon,
  filter_icon,
  share_icon,
  reset_icon,
  StyledAddIcon,
  InstagramIconSmall,
  FacebookIcon,
  FacebookLogoSmall,
} from '../../Common/SvgIcon/CommonSvgIcons';

import { useSelector, useDispatch } from 'react-redux';

import Sentiments from './Sentiments/SentimentsTab';
import Insights from './Insights/InsightsTab';
import ReviewsTab from './ReviewsTab/ReviewsTab';
import Overview from './OverViewTab/OverView';
import ClusterAnalysisTab from './ClusterAnalysisTab/ClusterAnalysisTab';
import CustomAnalysisTab from './CustomAnalysisTab/CustomAnalysisTab';
import { formatDate, formatDateFilter } from '../../utils';
import GenerateAnalysis from '../../components/Generate Analysis/GenerateAnalysis';

import {
  fetchCreatedTopic,
  createNewTopic,
  activeTopicList,
  FilterReportBasedOnDates,
} from '../../store/redux/slices/topicSlice';
import { useNavigate } from 'react-router-dom';
import PopUp from '../../Common/PopUp/PopUp';
import Button from '../../Common/Button/Button';
import SmallSelect from '../../Common/Select/SmallSelect';
import OverviewTabInsta from './OverViewTab/OverviewTabInsta';
import OverviewTabFb from './OverViewTab/OverviewTabFb';


const ActiveTopic = ({ job_id }) => {
  const [activeTab, setActiveTab] = useState('overViewTab');
  const [searchQuery, setSearchQuery] = useState('');
  const [shareOption, setShareOption] = useState(false);
  const [filterFlag, setFilterFlag] = useState(false);
  const [filterOption, setFilterOption] = useState('Bar Graph');
  const [filteredData, setFilteredData] = useState(null);
  const [resetFlag, setResetFlag] = useState(false);
  const [applyFilter, setApplyFilter] = useState(false);
  const [orgFilterOption, setOrgFilterOption] = useState('Bar Graph')
  const [orgView, setOrgView] = useState('')
  const [view, setView] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  let { topicPreviewData } = useSelector((state) => state.topicBuilder) || {};
  // const topicPreviewData = data;
  const { createdTopicData } = useSelector((state) => state.topicBuilder);

  let fetchedData;

  // const selectedTopic = createdTopicData.find(
  //   (topic) => topic.job_id === job_id
  // );
  const selectedTopic =
    createdTopicData?.reduce((foundTopic, topic) => {
      // If a topic with the matching job_id is found, assign it to foundTopic
      if (topic.job_id == job_id) {
        return topic;
      }
      return foundTopic; // Otherwise, return the previous foundTopic value
    }, null); // Initialize foundTopic with null

  if (typeof job_id === 'number' && selectedTopic) {
    topicPreviewData = selectedTopic;
    fetchedData = selectedTopic?.data;
  } else {

    fetchedData = topicPreviewData?.data;
  }

  useEffect(() => {
    if (typeof job_id === 'number' && (selectedTopic === undefined || selectedTopic === null)) {
      dispatch(fetchCreatedTopic(job_id)).then((res) => {
        fetchedData = res.payload.data?.data;
      });
    }
    if (selectedTopic || topicPreviewData) {
      setStartDate((prevStartDate) => {

        const newStartDate = selectedTopic
          ? formatDateFilter(selectedTopic?.start_date)
          : topicPreviewData
            ? formatDateFilter(topicPreviewData?.start_date)
            : '2024-01-01';

        // You can now compare newStartDate with prevStartDate if needed
        return prevStartDate !== newStartDate ? newStartDate : prevStartDate;




      });
    }
    setOrgFilterOption('Bar Graph');
    setOrgView('')
  }, [job_id, selectedTopic, topicPreviewData, dispatch]);


  const overView_tab = fetchedData?.overview_tab || {};
  const sentiments_tab = fetchedData?.sentiments_tab || {};
  const insights_tab = fetchedData?.insights_tab || {};
  const clusterAnalysis_tab = fetchedData?.cluster_analysis_tab || {};
  const initialStartDate = ''
  // selectedTopic ? formatDateFilter(selectedTopic?.start_date) : topicPreviewData ? formatDateFilter(topicPreviewData?.start_date) : '';
  const initialEndDate = ''
  // formatDateFilter(selectedTopic?.end_date) || formatDateFilter(topicPreviewData?.end_date) || '';
  const updateSearchQuery = (query) => {
    setSearchQuery(query);
  };
  // if (selectedTopic && startDate === '' && endDate === '') {
  //   setStartDate(
  //     formatDateFilter(selectedTopic?.start_date) || '2024/01/01'
  //   );
  //   setEndDate(formatDateFilter(selectedTopic?.end_date) || '2024/01/02');
  // } else if (topicPreviewData && startDate === '' && endDate === '') {

  //   setStartDate(
  //     formatDateFilter(topicPreviewData?.start_date) || '2024/01/01'
  //   );
  //   setEndDate(formatDateFilter(topicPreviewData?.end_date) || '2024/01/02');
  // }

  if (startDate === undefined) {
    console.log('date is undefined');
  }

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleFilter = () => {
    setApplyFilter(false)
    setFilterFlag(!filterFlag);
  };

  const handleClosePopup = () => {
    setFilterFlag(false);
  };

  const handleShareClick = () => {
    setShareOption(!shareOption);
  };

  const handleApply = () => {
    setApplyFilter(true)
    setFilterFlag(!filterFlag);
    setOrgFilterOption(filterOption)
    setOrgView(view)
  };

  const hadleFilterChange = (selectedOption) => {
    setFilterOption(selectedOption);
  };

  const handleReset = () => {
    setStartDate(initialStartDate);
    setEndDate(initialEndDate);
    setResetFlag(false);
    setFilteredData(null)
  };

  const dateHandler = (e) => {
    const { name, value } = e.target;
    if (name === 'firstPostDate') {
      setStartDate(value);
    } else if (name === 'lastPostDate') {
      setEndDate(value);
      dispatch(FilterReportBasedOnDates({ startDate, endDate: value, job_id }))
        .then((res) => {
          setFilteredData(res.payload.response);
        })
        .catch((error) => {
          console.error('Error:', error); // Handling any errors
        });
    }
    setResetFlag(true);
  };

  const transformText = (inputText) => {
    const words = inputText?.split(' ');
    const transformedWords = words?.map(word => word?.charAt(0).toUpperCase() + word?.slice(1));
    return transformedWords?.join(' ')
  };
  const renderTabContent = () => {
    switch (activeTab) {
      case 'overViewTab':
        return (
          topicPreviewData?.channel === 'twitter' ?
            <Overview
              overview_tab={overView_tab}
              startDate={selectedTopic?.start_date || topicPreviewData?.start_date}
              filteredStartDate={startDate}
              filteredEndDate={endDate}
              endDate={selectedTopic?.end_date || topicPreviewData?.end_date}
              filterOption={orgFilterOption}
              filteredData={filteredData?.data?.overview_tab}
              resetFlag={resetFlag}
              view={orgView}
            />
            :
            topicPreviewData?.channel === 'facebook' ?
              <OverviewTabFb
                overview_tab={overView_tab}
                startDate={selectedTopic?.start_date || topicPreviewData?.start_date}
                filteredStartDate={startDate}
                filteredEndDate={endDate}
                endDate={selectedTopic?.end_date || topicPreviewData?.end_date}
                filterOption={orgFilterOption}
                filteredData={filteredData?.data?.overview_tab}
                resetFlag={resetFlag}
                view={orgView}
              />
              :
              <OverviewTabInsta
                overview_tab={overView_tab}
                startDate={selectedTopic?.start_date || topicPreviewData?.start_date}
                filteredStartDate={startDate}
                filteredEndDate={endDate}
                endDate={selectedTopic?.end_date || topicPreviewData?.end_date}
                filterOption={orgFilterOption}
                filteredData={filteredData?.data?.overview_tab}
                resetFlag={resetFlag}
                view={orgView}
              />
        );
      case 'sentimentsTab':
        return (
          <Sentiments
            sentiments_tab={sentiments_tab}
            startDate={selectedTopic?.start_date || topicPreviewData?.start_date}
            endDate={selectedTopic?.end_date || topicPreviewData?.end_date}
            filteredStartDate={startDate}
            filteredEndDate={endDate}
            filterOption={orgFilterOption}
            resetFlag={resetFlag}
            filteredData={filteredData?.data?.sentiments_tab}
            view={orgView}
            channel={topicPreviewData?.channel}
          />
        );
      case 'insightTab':
        return (
          <Insights
            insights_data={insights_tab}
            startDate={selectedTopic?.start_date || topicPreviewData?.start_date}
            endDate={selectedTopic?.end_date || topicPreviewData?.end_date}
            filteredStartDate={startDate}
            filteredEndDate={endDate}
            filterOption={orgFilterOption}
            filteredData={filteredData?.data?.insights_tab}
            view={orgView}
            resetFlag={resetFlag}
            channel={topicPreviewData?.channel}
          />
        );
      case 'clusterAnalysis':
        return (
          <ClusterAnalysisTab
            cluster_analysis={clusterAnalysis_tab}
            startDate={selectedTopic?.start_date || topicPreviewData?.start_date}
            endDate={selectedTopic?.end_date || topicPreviewData?.end_date}
            filteredData={filteredData?.data?.cluster_analysis}
            resetFlag={resetFlag}
            filteredStartDate={startDate}
            filteredEndDate={endDate}
            channel={topicPreviewData?.channel}
            job_id={job_id}
          />
        );
      case 'customAnalysis':
        return (
          <CustomAnalysisTab
            job_id={job_id}
            preview_uuid={topicPreviewData?.preview_uuid}
            onUpdateSearchQuery={updateSearchQuery}
            channel={topicPreviewData?.channel}
          />
        );
      case 'reviewsTab':
        return (
          <ReviewsTab
            startDate={selectedTopic?.start_date || topicPreviewData?.start_date}
            endDate={selectedTopic?.end_date || topicPreviewData?.end_date}
            // startDate={startDate}
            // endDate={endDate}6
            preview_uuid={topicPreviewData?.preview_uuid}
            job_id={topicPreviewData?.job_id}
            filteredReviews={filteredData}
            channel={topicPreviewData?.channel}
          />
        );
      default:
        return null;
    }
  };

  const handleClear = () => {
    setOrgFilterOption('Bar Graph');
    setOrgView(null);
  };

  const handleCreatedTopic = () => {
    dispatch(createNewTopic(topicPreviewData?.inputData)).then((res) => {
      dispatch(activeTopicList());
      dispatch(fetchCreatedTopic(res.payload.data.job_id)).then((res) => {
        fetchedData = res.payload.data?.data;
      });

      navigate('/topic/job_' + res.payload.data.job_id);
    });
  };
  return (
    <Wrapper>
      {shareOption && (
        <GenerateAnalysis
          open={shareOption}
          onClose={() => setShareOption(false)}
          moduleType={topicPreviewData?.channel == 'facebook' ? 'Facebook' : topicPreviewData?.channel == 'instagram' ? 'Instagram' : 'Twitter'}
          title={topicPreviewData?.title}
          job_id={job_id}
          /* searchQuery = {searchQuery} */
          reportData={fetchedData}
        /*  imageResponse = {localStorage.getItem('imageResponse')}
         tableResponse = {localStorage.getItem('tableData')}  */
        />
      )}
      {filterFlag && (
        <PopUp onClose={handleClosePopup}>
          <DeletePopupWrapper>
            <p>All Filters</p>
            <DivContainer>
              <SmallSelect
                option={filterOption}
                options={[
                  'Bar Graph',
                  'Donut Chart',
                  'Pie Chart',
                  'Funnel Chart',
                ]}
                onChange={(selectedOption) => hadleFilterChange(selectedOption)}
                placeholder='Visual View'
              />
              {
                filterOption == 'Bar Graph' &&
                <RadioContainer>
                  <input
                    type='radio'
                    name='viewType'
                    value='horizontal'
                    onChange={(e) => setView(e.target.value)}
                  />{' '}
                  Horizontal View
                  <input
                    type='radio'
                    name='viewType'
                    value='vertical'
                    onChange={(e) => setView(e.target.value)}
                  />{' '}
                  Vertical View
                </RadioContainer>
              }

            </DivContainer>
            <div>
              <Button value={'Cancel'} onClick={() => setFilterFlag(false)} />
              <Button value={'Clear All'} onClick={handleClear} />
              <Button value={'Apply'} onClick={handleApply} />
            </div>
          </DeletePopupWrapper>
        </PopUp>
      )}
      <HeaderWrapper>
        <Span1>{transformText(topicPreviewData?.title)}</Span1>
        <Span2>{topicPreviewData?.description}</Span2>
        <StyledContainer>
          <ChannelsText>Channel:</ChannelsText>
          <TwitterText>
            <Icon>{topicPreviewData?.channel === 'twitter' ? X_icon : topicPreviewData?.channel === 'instagram' ? InstagramIconSmall : topicPreviewData?.channel === 'facebook' ? FacebookLogoSmall : ''}</Icon>
            {transformText(topicPreviewData?.channel)}
          </TwitterText>
        </StyledContainer>
        <QueryPara>
          <b>Query Used:&nbsp;</b> {topicPreviewData?.keywords_string}
          <br />
          {/* <br /> lang: en since: 2023-01-01 until: 2023-05-18 geocode: 20.5937,78.9629,2000 km */}
        </QueryPara>
        {typeof job_id !== 'number' && (
          <CreateTopic onClick={handleCreatedTopic}>
            Create Topic
            {StyledAddIcon}
          </CreateTopic>
        )}
      </HeaderWrapper>
      <FilterWrapper>
        <DateWrapper>
          <div>
            <div>First Post Date:</div>
            <input
              type='date'
              name='firstPostDate'
              value={startDate || initialStartDate}
              onChange={(e) => dateHandler(e)}
              min={initialStartDate}
              max={initialEndDate}
            />
          </div>
          <div>
            <div>Last Post Date:</div>
            <input
              type='date'
              name='lastPostDate'
              value={endDate || initialEndDate}
              onChange={(e) => dateHandler(e)}
              min={startDate}
              max={initialEndDate}
            />
          </div>
          {typeof job_id === 'number' && (
            <button className='button' onClick={handleReset}>
              Reset
              {reset_icon}
            </button>
          )}
        </DateWrapper>
        <div className='buttonContainer'>
          <button className='button' onClick={handleFilter}>
            Filters
            {filter_icon}
          </button>
          {typeof job_id === 'number' && (
            <button className='button' onClick={handleShareClick}>
              Share
              {share_icon}
            </button>
          )}
        </div>
      </FilterWrapper>
      <TabHeading>
        <div
          className={activeTab === 'overViewTab' ? 'activePlanTab' : ''}
          onClick={() => handleTabClick('overViewTab')}
        >
          Overviews
        </div>
        <div
          className={activeTab === 'sentimentsTab' ? 'activePlanTab' : ''}
          onClick={() => handleTabClick('sentimentsTab')}
        >
          Sentiments
        </div>
        <div
          className={activeTab === 'insightTab' ? 'activePlanTab' : ''}
          onClick={() => handleTabClick('insightTab')}
        >
          Insights
        </div>
        <div
          className={activeTab === 'clusterAnalysis' ? 'activePlanTab' : ''}
          onClick={() => handleTabClick('clusterAnalysis')}
        >
          Cluster Analysis
        </div>
        <div
          className={activeTab === 'customAnalysis' ? 'activePlanTab' : ''}
          onClick={() => handleTabClick('customAnalysis')}
        >
          Custom Analysis
        </div>
        <div
          className={activeTab === 'reviewsTab' ? 'activePlanTab' : ''}
          onClick={() => handleTabClick('reviewsTab')}
        >
          Posts
        </div>
      </TabHeading>
      {renderTabContent()}
    </Wrapper>
  );
};

export default ActiveTopic;
