import React, { useEffect, useState } from 'react';
import MessagesTab from '../../../components/MessageTab/MessageTab';
import { useDispatch, useSelector } from 'react-redux';

import {
  reviewsForTopic,
  reviewsForCreatedTopic,
  reviewsFilterTopic,
  reviewsFilterTopicPreview,
  reviewsForDownload
} from '../../../store/redux/slices/topicSlice';
import styled from 'styled-components';
import { FacebookLogo, InstagramIconSmall, X_icon } from '../../../Common/SvgIcon/CommonSvgIcons';
import Button from '../../../Common/Button/Button';
import { formatDateFilter } from '../../../utils';

const Wrapper = styled.div`
  width: 96%;
margin-left:auto;
margin-right:auto;
margin-top:40px;
`;

const LogoImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 8px 0px #a2a2a23d;
  border: 1px solid #d9d9d9;
  width: 43px;
  height: 42px;
  border-radius: 5px;
  & svg {
    width: 20px;
    height: 20px;
    padding-top: 5px;
  }
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: end;
  gap: 30px;
  font-size: 15px;
  font-weight: 600;
  padding: 30px 0px;
`;

const ContainerInput = styled.div`
  display: flex;
  align-items: end;
  gap: 10px;
  font-size: 15px;
  font-weight: 600;

  & input {
    box-shadow: 0px 0px 8px 0px #a2a2a23d;
    border: 1px solid #d9d9d9;
    margin-top: 5px;
    margin-left: -3px;
    font-family: Open Sans;
    font-size: 15px;
    font-weight: 400;
    color: #636363;

    padding: 5px 10px;
    border-radius: 5px;
  }
`;

function ReviewsTab({
  download,
  showReport,
  startDate = '',
  endDate = '',
  preview_uuid = '',
  job_id,
  email_id,
  filteredReviews,
  channel,
  hideDropdown
}) {
  const dispatch = useDispatch();
  const [firstDate, setFirstDate] = useState(startDate);
  const [lastDate, setLastDate] = useState(endDate);
  // const [firstTime, setFirstTime] = useState('');
  // const [lastTime, setLastTime] = useState('');
  const [filterData, setFilteredData] = useState(null)

  const dateHandler = (e) => {
    const { name, value } = e.target;
    if (name === 'firstPostDate') {
      setFirstDate(value);
    } else if (name === 'lastPostDate') {
      setLastDate(value);
    }
  };

  // const timeHandler = (e) => {
  //   const { name, value } = e.target;
  //   if (name === 'firstPostTime') {
  //     setFirstTime(value);
  //   } else if (name === 'lastPostTime') {
  //     setLastTime(value);
  //   }
  // };

  const handleSave = () => {
    const dates = {
      start_date: firstDate, end_date: lastDate
    }
    dispatch(
      preview_uuid ? reviewsFilterTopicPreview({ dates, preview_uuid }) : reviewsFilterTopic({ dates, job_id })
    )
      .then((res) => {
        setFilteredData(res.payload.data.reviews);
        // setFilter(true)
      })
      .catch((error) => {
        console.error('Error:', error); // Handling any errors
      });
  }
  const { reviewsForTopicData } =
    useSelector((state) => state.topicBuilder) || null;

  useEffect(() => {
    if (download || showReport) {
      dispatch(reviewsForDownload({ job_id, email_id }));
    } else if (job_id && reviewsForTopicData === null && filteredReviews) {
      const dates = {
        start_date: formatDateFilter(startDate), end_date: formatDateFilter(endDate)
      }
      dispatch(reviewsFilterTopic({ dates, job_id }))
    }else if(job_id && reviewsForTopicData === null){
      dispatch(reviewsForCreatedTopic(job_id ));
    } else if (reviewsForTopicData === null || reviewsForTopicData?.length === 0) {
      dispatch(reviewsForTopic(preview_uuid));
    }
  }, [job_id]);
  return (
    <Wrapper>
      {
        !hideDropdown &&
        <Container>
          <LogoImage>{channel === 'facebook' ? FacebookLogo : channel === 'twitter' ? X_icon : InstagramIconSmall}</LogoImage>
          <ContainerInput>
            <div>
              <div>First Date:</div>
              <input
                type="date"
                name="firstPostDate"
                value={firstDate}
                onChange={dateHandler}
              />
            </div>
            {/* {
            channel === 'twitter' &&
              <div>
                <div>Time:</div>
                <input
                  type="time"
                  name="firstPostTime"
                  value={firstTime}
                  onChange={ timeHandler}
                />
              </div>
          } */}

          </ContainerInput>
          <ContainerInput>
            <div>
              <div>Last Date:</div>
              <input
                type="date"
                name="lastPostDate"
                value={lastDate}
                onChange={dateHandler}
              />
            </div>
            {/* {
            channel === 'twitter' &&
            <div>
              <div>Time:</div>
              <input
                type="time"
                name="lastPostTime"
                value={lastTime}
                onChange={timeHandler}
              />
            </div>
          } */}

          </ContainerInput>
          <Button
            value={'Save'}
            onClick={handleSave}
            style={{
              width: '100px',
              borderRadius: '10px',
              height: '35px',
              fontSize: '18px'
            }}
          />
        </Container>
      }
      <MessagesTab
        download={download}
        graphStyles={{
          gridTemplateColumns: channel === 'facebook' ? '45% 1fr 1fr 1fr 1fr' : '45% 1fr 1fr 1fr'
        }}
        reviews={filterData ? filterData : reviewsForTopicData || []}
        filterCol={channel === 'facebook' ? [2, 3, 4, 5] : [2, 3, 4]}
        // startDate={startDate}
        // endDate={endDate}
        columns={
          channel === 'facebook' ?
            ['Posts', 'Profile', 'Likes', 'Comments', 'Shares']
            :
            channel === 'twitter' ?
              ['Posts', 'Profile', 'Followers', 'Engagement']
              : ['Posts', 'Profile', 'Likes', 'Comments']
        }
        channel={channel}
      />
    </Wrapper>
  );
}

export default ReviewsTab;
