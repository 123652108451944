




import React, { useState } from 'react';
import styled from 'styled-components';
import {
  AmazonIconSmall,
  FlipkartIconSmall,
  FlipkartLogo,
  StarIcon,
} from '../../../../../Common/SvgIcon/CommonSvgIcons';
// import PieChartComponent from "./SentimentCharts";
import DonutChart from '../../../../../Common/SentimentCharts/SentimentCharts';
// import GenderBarGraph from './GenderGraph';
import AverageRatingGraph from '../../../../../Common/LineGraph/LineGraph';
import RatingDonutChart from '../../../../../Common/RatingDonutChart/DonutChart';
import GraphDropdown from '../../../../../Common/GraphDropDown/GraphDropdown';
import Lottie from 'lottie-react';
import FunnelGraph from '../../../../../Common/FunnelGraph/FunnelGraph';
import VerifiedNonVerifiedUserGraph from '../../../../../Common/VerifiedNonVerifiedUserGraph/VerifiedNonVerifiedUserGraph';
import TrendsInUserActivity from '../../ReportDownload/TrendInUserActivity';
import GenderDemoGraphics from '../../../../../components/GenderDemoGraphics/GenderDemoGraphics';
import GenderRating from '../../../../../components/GenderRating/GenderRating';
import BarGraph from '../../../../../Common/BarGraph/BarGraph';
import VerticalBarGraph from '../../../../../Common/BarGraph/VarticalBargraph';
import Loader from '../../../../../assests/animation/Loader2.json';
import GraphHOC from '../GraphHOC';

const Wrapper = styled.div`
  display: flex;
  height: auto;
  width: 95%;
  flex-direction: column;
  margin-bottom: 10px;
  /*  background:red; */
`;

const FlexWrapper1 = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  /*  gap: 45px 10px;  */
  margin: 40px 0px 0px 20px;
`;

const ContentWrapper1 = styled.div`
  width: 50%;
  height: 380px;
  flex-shrink: 0;

  border-radius: 15px;
  border: 1px solid #d9d9d9;
  justify-content: space-between;

  p {
    padding: 0.5em 0 0 2em;
    align-items: center;
    color: #636363;

    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  &>span: first-child {
    /* display: flex; */
    /* margin: 20px 0px 0px 20px; */
    color: #636363;
    font-size: 15px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
  }
`;

const ContentWrapper2 = styled.div`
  display: flex;
  margin-left: 15px;
  flex-direction: column;
  width: 50%;
  height: 380px;
  border-radius: 15px;
  border: 1px solid #d9d9d9;

  p {
    padding: 0.532em 0 0 2em;
    color: #636363;
    margin-bottom: 50px;

    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  &>span: first-child {
    /* display:flex; */
    color: #636363;
    /* margin: 20px 0px 0px 20px; */
    font-size: 15px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
  }
  &>div: first-child {
    flex: 1;
  }
`;

const FlexWrapper2 = styled(FlexWrapper1)`
  margin-top: 20px;
`;

const ContentWrapperFlipkart = styled(ContentWrapper1)`
  width: 100%;
`;

export const TextWrapper = styled.div`
  width: 389px;
  display: flex;
  height: 230px;
  margin: 40px 0px 0px 55px;

  border-radius: 36px 36px 0px 36px;
  background: rgba(111, 98, 254, 0.22);

  span {
    //display: flex;
    margin: 80px 0px 0px 20px;
    width: 230px;
    height: 85px;
    flex-direction: column;

    color: #000;
    text-align: center;
    font-size: 17px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 400;
  }

  div {
    width: 90px;
    height: 86px;
    flex-shrink: 0;
    margin: -20px 0px 0px -30px;

    border-radius: 90px;
    border: 1px solid rgba(85, 172, 238, 0.84);
    background: #fff;
    box-shadow: 0px 0px 11px 0px rgba(63, 63, 63, 0.25) inset;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  & svg {
    width: 30px;
  }
`;

const Span = styled.div`
  color: rgba(0, 0, 0, 0.87);

  font-family: Open Sans;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  align-self: flex-end;

  text-align: right;
  justify-content: space-between;
  margin-right: 10px;
  margin-bottom: 5px;
`;

const GraphContainer = styled.div`
  flex: 1;
`;

const GraphWrapper = styled.div`
  /* display: flex; */
  width: 100%;
  height: 100%;
  border-radius: 15px;
  border: 1px solid #d9d9d9;
  margin: 20px 0px 0px 20px;
`;

const ChildContainer = styled.div`
  /* flex: 1; */
  height: 100%;
  min-height:380px;
  flex-shrink: 0;
  border-radius: 15px;
  width: 50%;
  border: 1px solid #d9d9d9;
  justify-content: space-between;

  /* display: flex;
  justify-content: center; */
  align-items: center;
  box-sizing: border-box;
  margin-left: 15px;
`;

const Title = styled.p`
  color: #636363;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 500;

  margin: 10px 0px 0px 10px;
`;

const Div = styled.div`
  justify-content: center;
  margin-top: 20px;
`;

function OverViewTab({
  chatterData,
  plan,
  selectedReport,
  filteredData,
  reset,
  filterOption,
  view,
  filteredStartDate,
  filteredEndDate,
  child,
  hideDropdown
}) {
  /* const [timeSpan, setTimeSpan] = useState('day'); */
  const options = ['Bar Graph', 'Pie Chart', 'Donut Chart', 'Funnel Chart'];

  let planSpecificData;
  if (selectedReport) {
    if (plan === 'BrandAstraPro') {
      planSpecificData = selectedReport?.data.overview_tab;
    } else if (plan === 'BrandEssence') {
      planSpecificData = selectedReport?.data.overview_tab;
      // planSpecificData = selectedReport?.BrandAstraEssence.overview_tab;
    } else if (plan === 'BrandAstraElite') {
      planSpecificData = selectedReport?.BrandAstraElite;
    } else {

      planSpecificData = selectedReport?.data.overview_tab;
    }
  } else if (chatterData) {
    if (plan === 'BrandAstraPro') {
      planSpecificData = chatterData?.data.overview_tab;
    } else if (plan === 'BrandEssence') {
      planSpecificData = chatterData?.data.overview_tab;
      // planSpecificData = chatterData?.BrandAstraEssence.overview_tab;
    } else if (plan === 'BrandAstraElite') {
      planSpecificData = chatterData?.BrandAstraElite;
    } else {
      planSpecificData = chatterData?.data.overview_tab;
    }
  }

  const averageRating =
    planSpecificData?.overall_customer_satisfaction?.average_rating || 0;

  const percentageValue = filteredData
    ? filteredData?.gender_based_avg_rating
    : planSpecificData?.gender_based_avg_rating;

  const genderBasedRatingData = [
    {
      name: 'Male ',
      value: parseFloat(percentageValue?.male_percentage),
      fill: '#6640B8',
    },
    {
      name: 'Female',
      value: parseFloat(percentageValue?.female_percentage),
      fill: '#EAAABD',
    },
    {
      name: 'Unknown',
      value: parseFloat(percentageValue?.unknown_percentage),
      fill: '#D2D2D4',
    },
  ];

  const verifiedAndNonVerifiedData = filteredData
    ? filteredData?.verified_non_verified_users
    : planSpecificData?.verified_non_verified_users;

  const verifiedPurchaseRating =
    chatterData?.data?.sentiments?.verified_purchase_impact
      .verified_average_rating ||
    selectedReport?.data?.overview_tab?.overall_customer_satisfaction?.verified_purchase;

  const nonVerifiedPurchaseRating =
    chatterData?.data?.sentiments?.verified_purchase_impact
      .non_verified_avg_rating ||
    selectedReport?.data?.overview_tab?.overall_customer_satisfaction?.non_verified_purchase;

  const renderGenderBasedRatingGraph = {
    'Bar Graph':
      view === 'vertical' ? (
        <VerticalBarGraph
          data={genderBasedRatingData}
          type={StarIcon}
          starRating={true}
        />
      ) : (
        <BarGraph
          data={genderBasedRatingData}
          type={StarIcon}
          starRating={true}
          borderRadius={'0px 12px 12px 0px'}
          barHeight={'45px'}
          maxWidth={'80%'}
        />
      ),
    'Pie Chart': <RatingDonutChart data={genderBasedRatingData} />,
    'Donut Chart': <DonutChart data={genderBasedRatingData} align="left" />,
    'Funnel Chart': <FunnelGraph data={genderBasedRatingData} />,
  };

  const genderBasedAverageRatingGraph =
    renderGenderBasedRatingGraph[filterOption];

  const userRatingValue = filteredData
    ? filteredData?.customer_rating?.customer_reviews
    : planSpecificData?.customer_ratings;
  const userRating = [
    {
      name: '5 star ',
      value: parseFloat(userRatingValue?.five_star_ratings),
      fill: '#FF4500',
    },
    {
      name: '4 star',
      value: parseFloat(userRatingValue?.four_star_ratings),
      fill: '#8B4513',
    },
    {
      name: '3 star',
      value: parseFloat(userRatingValue?.three_star_ratings),
      fill: '#7CFC00',
    },
    {
      name: '2 star',
      value: parseFloat(userRatingValue?.two_star_ratings),
      fill: '#FFFF00',
    },
    {
      name: '1 star',
      value: parseFloat(userRatingValue?.one_star_ratings),
      fill: '#008080',
    },
  ];

  const renderScreen = {
    'Bar Graph':
      view === 'vertical' ? (
        <VerticalBarGraph data={userRating} type={'%'} />
      ) : (
        <BarGraph
          data={userRating}
          type={'%'}
          borderRadius={'0px 0px 0px 0px'}
          barHeight={'30px'}
          maxWidth={'80%'}
        />
      ),
    'Pie Chart': (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flex: 1,
        }}
      >
        <RatingDonutChart data={userRating} type='percentage' />
      </div>
    ),
    'Donut Chart': (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flex: 1,
        }}
      >
        {' '}
        <DonutChart data={userRating} type='percentage' />
      </div>
    ),
    'Funnel Chart': (
      <FunnelGraph data={userRating} type={'%'} maxWidth={'80%'} />
    ),
  };

  const customerRatingGraph = renderScreen[filterOption];
  return (
    <>
      {reset === true && filteredData === undefined ? (
        <div style={{ marginTop: '-80px' }}>
          <Lottie
            data-testid='loader'
            animationData={Loader}
            loop={true}
            width='100px'
            height='100px'
          />
        </div>
      ) : (
        <Wrapper>
          <FlexWrapper1>
            <ContentWrapper1>
              <p>Overall Reviews</p>
              <TextWrapper>
                {chatterData?.channel === 'amazon' || selectedReport?.channel === 'amazon' ? (
                  <IconWrapper>{AmazonIconSmall}</IconWrapper>
                ) :
                  chatterData?.channel === 'flipkart' || selectedReport?.channel === 'flipkart' ?
                    (
                      <IconWrapper>{FlipkartLogo}</IconWrapper>
                    )
                    :
                    <></>
                }
                <span>
                  {filteredData
                    ? filteredData?.customer_rating?.total_reviews
                    : planSpecificData?.total_count}{' '}
                  Relevant reviews were observed from{' '}
                  {reset
                    ? filteredStartDate
                    : chatterData?.start_date ||
                    selectedReport?.start_date}{' '}
                  to{' '}
                  {reset
                    ? filteredEndDate
                    : chatterData?.end_date || selectedReport?.end_date}
                  .
                </span>
              </TextWrapper>
            </ContentWrapper1>
            {/* <CustomerRating
          data={planSpecificData}
          startDate={chatterData?.start_date}
          endDate={chatterData?.end_date}
        /> */}

            {child?.length > 0 && child[0]?.value == true &&
              <>
                {chatterData?.channel === 'flipkart' ||
                  selectedReport?.channel === 'flipkart' ? (
                  <ContentWrapper2>
                    <p>Overall Customer Satisfaction</p>
                    <GraphContainer>
                      <AverageRatingGraph
                        rating={averageRating}
                        backgroundColor='#6640B8'
                        title='Average Rating'
                      />
                    </GraphContainer>

                    <Span>
                      This data is observed from{' '}
                      {reset
                        ? filteredStartDate
                        : chatterData?.start_date ||
                        selectedReport?.start_date}{' '}
                      to{' '}
                      {reset
                        ? filteredEndDate
                        : chatterData?.end_date || selectedReport?.end_date}
                    </Span>
                  </ContentWrapper2>
                ) : (
                  <ContentWrapper2>
                    <p>Overall Customer Satisfaction</p>
                    <GraphContainer>
                      {
                        child[0]?.child[0].value == true &&

                        <AverageRatingGraph
                          rating={averageRating}
                          backgroundColor='#6640B8'
                          title='Average Rating'
                        />
                      }
                      {
                        child[0]?.child[1].value == true &&

                        <AverageRatingGraph
                          rating={verifiedPurchaseRating}
                          backgroundColor='#5FE495'
                          title='Verified Purchase'
                        />
                      }
                      {
                        child[0]?.child[2].value == true &&

                        <AverageRatingGraph
                          rating={nonVerifiedPurchaseRating}
                          title='Non Verified Purchase'
                        />
                      }
                    </GraphContainer>

                    <Span>
                      This data is observed from{' '}
                      {reset
                        ? filteredStartDate
                        : chatterData?.start_date ||
                        selectedReport?.start_date}{' '}
                      to{' '}
                      {reset
                        ? filteredEndDate
                        : chatterData?.end_date || selectedReport?.end_date}
                    </Span>
                  </ContentWrapper2>
                )}
              </>
            }
          </FlexWrapper1>
          <div>
            {chatterData?.channel === 'flipkart' ||
              selectedReport?.channel === 'flipkart' ? (
              <FlexWrapper2>
                {filterOption ? (
                  <ContentWrapperFlipkart>
                    <Title>Customer Rating</Title>
                    <Div>{customerRatingGraph}</Div>
                  </ContentWrapperFlipkart>
                ) : (
                  child?.length > 0 && child[1]?.value == true &&
                  <ContentWrapperFlipkart>
                    <GraphHOC
                      title={'Customer Rating'}
                      options={options}
                      renderScreen={renderScreen}
                      startDate={
                        reset
                          ? filteredStartDate
                          : chatterData?.start_date || selectedReport?.start_date
                      }
                      endDate={
                        reset
                          ? filteredEndDate
                          : chatterData?.end_date || selectedReport?.end_date
                      }
                      option={
                        child[1]?.child?.find(i => i?.value === true)?.name
                      }
                      hideDropdown={hideDropdown}
                    />
                  </ContentWrapperFlipkart>
                )}
              </FlexWrapper2>
            ) : (
              <FlexWrapper2>
                {filterOption ? (
                  <ContentWrapper1>
                    <Title>Customer Rating</Title>
                    <Div>{customerRatingGraph}</Div>
                  </ContentWrapper1>
                ) : (
                  child?.length > 0 && child[1]?.value == true &&
                  <ContentWrapper1>
                    <GraphHOC
                      title={'Customer Rating'}
                      options={options}
                      renderScreen={renderScreen}
                      startDate={
                        reset
                          ? filteredStartDate
                          : chatterData?.start_date || selectedReport?.start_date
                      }
                      endDate={
                        reset
                          ? filteredEndDate
                          : chatterData?.end_date || selectedReport?.end_date
                      }
                      option={
                        child[1]?.child?.find(i => i?.value === true)?.name
                      }
                      hideDropdown={hideDropdown}
                    />
                  </ContentWrapper1>
                )}
                {child?.length > 0 && child[3]?.value == true &&
                  <VerifiedNonVerifiedUserGraph data={verifiedAndNonVerifiedData} />
                }
              </FlexWrapper2>
            )}
          </div>
          <FlexWrapper2>
            {
              child?.length > 0 && child[2]?.value == true &&
              <GenderDemoGraphics
                options={options}
                data={planSpecificData}
                startDate={
                  chatterData?.start_date || selectedReport?.start_date
                }
                endDate={
                  chatterData?.end_date || selectedReport?.end_date
                }
                hideDropdown={hideDropdown}
              />
            }

            {filterOption ? (
              <ChildContainer>
                <Title>Gender Based Average Rating</Title>
                <Div>{genderBasedAverageRatingGraph}</Div>
              </ChildContainer>
            ) : (
              child?.length > 0 && child[4]?.value == true &&
              <ChildContainer>
                <GraphHOC
                  title={'Gender Based Average Rating'}
                  options={options}
                  renderScreen={renderGenderBasedRatingGraph}
                  startDate={
                    reset
                      ? filteredStartDate
                      : chatterData?.start_date || selectedReport?.start_date
                  }
                  endDate={
                    reset
                      ? filteredEndDate
                      : chatterData?.end_date || selectedReport?.end_date
                  }
                  option={
                    child[4]?.child?.find(i => i?.value === true)?.name
                  }
                  hideDropdown={hideDropdown}
                />
              </ChildContainer>
            )}
          </FlexWrapper2>
          <div className="pdf-page" style={{
            pageBreakBefore: 'auto', pageBreakAfter: 'auto', pageBreakInside: 'avoid'
          }}>
            {child?.length > 0 && child[5]?.value == true &&
              <GenderRating
                data={filteredData ? filteredData : planSpecificData}
                startDate={
                  reset
                    ? filteredStartDate
                    : chatterData?.start_date || selectedReport?.start_date
                }
                endDate={
                  reset
                    ? filteredEndDate
                    : chatterData?.end_date || selectedReport?.end_date
                }
              />
            }
          </div>
          <div >
            {child?.length > 0 && child[6]?.value == true &&
              <GraphWrapper className="pdf-page" style={{
                pageBreakBefore: 'auto', pageBreakAfter: 'auto', pageBreakInside: 'avoid',
              }}>
                <TrendsInUserActivity
                  title='Trends In User Activity'
                  showLineGraph={child[6]?.child[0]?.value == true ? 'Line Graph' : 'Area Graph'}
                  data1={planSpecificData}
                  ylabel='Review'
                  hideDropdown={hideDropdown}
                  startDate={
                    reset
                      ? filteredStartDate
                      : chatterData?.start_date || selectedReport?.start_date
                  }
                  endDate={
                    reset
                      ? filteredEndDate
                      : chatterData?.end_date || selectedReport?.end_date
                  }
                />
              </GraphWrapper>
            }
          </div>
        </Wrapper>
      )}
    </>
  );
}

export default OverViewTab;
