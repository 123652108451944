import React from 'react';
import styled from 'styled-components';
import SettingWidgetIcon from '../../Common/SvgIcon/SettingWidgetIcon';
import Tabs from './Tabs';

const Sidebar = styled.div`
  border-right: 1px solid rgba(162, 162, 162, 0.35);
  overflow-y: scroll;
`;

const SettingHeader = styled.div`
  width: 85%;
  display: flex;
  justify-content: space-between;
  margin: 20px auto;
  padding-bottom: 18px;
  font-weight: 600;
  font-size: 20px;
  position: relative;

  &::after {
    position: absolute;
    content: '';
    top: 90%;
    left: 50%;
    transform: translate(-50%);
    width: 92%;
    height: 2px;
    border-radius: 10px;
    background-color: rgba(99, 99, 99, 0.2);
  }
`;

const NewSideBar = ({ headerName, tabs, initialActiveTab = 1, buttonTitle }) => {

  return (
    <Sidebar>
      <SettingHeader>
        <div>{headerName}</div>
        {SettingWidgetIcon}
      </SettingHeader>
      {tabs.map((data) => (
        <Tabs value={data} active={initialActiveTab} key={data.id} name = {headerName}  buttonTitle={buttonTitle}/>
      ))}
    </Sidebar>
  );
};

export default NewSideBar;
