import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import ClusterAnalysisGraph from '../../../../components/ClusterAnalysisGraph/ClusterAnalysisGraph';

const Wrapper = styled.div`
  padding: 30px 30px;
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const Container = styled.div`
  min-height: 100px;
  padding: 25px 20px 25px 50px;

  border-radius: 15px;
  border: 1px solid #d9d9d9;

  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  color: #636363;

  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
  margin-left: -35px;
  margin-bottom: 15px;
`;
const Title2 = styled.div`
  color: #636363;

  font-family: Montserrat;
  font-size: 15px;
  font-weight: 500;
  margin-left: -35px;
  margin-bottom: 15px;
`;
const Footer = styled.div`
  color: rgba(0, 0, 0, 0.87);
  font-family: Open Sans;
  font-size: 11px;
  font-weight: 400;
  text-align: right;
  margin-top: 15px;
`;

const ClusterAnalysisTab = ({
  cluster_analysis = {},
  startDate = '',
  endDate = '',
  filteredData,
  resetFlag,
  filteredStartDate,
  filteredEndDate,
  child
}) => {
  if (Object.keys(cluster_analysis).length === 0) {
    return <h2 style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '30px' }}>No data available</h2>;
  }

  const sentimentColor = {
    1: '#73EBA4',
    0: '#FBD77D',
    '-1': '#FC6758',
  };
  const sentimentLabel = {
    '-1': 'Negative',
    1: 'Positive',
    0: 'Neutral',
  };

  const emotionLabel = {
    fear: 'Fear',
    surprise: 'Surprise',
    neutral: 'Neutral',
    anger: 'anger',
    disgust: 'Disgust',
    sadness: 'Sadness',
    joy: 'Joy',
  };

  const emotionColor = {
    fear: '#FC9E58',
    surprise: '#B7DC68',
    neutral: '#F5CC61',
    anger: '#F74945',
    disgust: '#9071CE',
    sadness: '#4B9AE9',
    joy: '#FE00D5',
  };

  return (
    <Wrapper >
      {
        child?.length > 0 && (child[0]?.value || child[1]?.value) &&
        <Container className="pdf-page" style={{
          pageBreakBefore: 'auto', pageBreakAfter: 'auto', pageBreakInside: 'avoid'
        }}>
        <Title>Cluster Analysis </Title>
          <Title2>Summaries of Sentiments and Emotions </Title2>
          {/* <ClusterAnalysisGraph
            xAxisValues={resetFlag === true && filteredData ? filteredData?.sentiment_data['X-axis'] : cluster_analysis?.sentiment_data['X-axis']}
            yAxisValues={resetFlag === true && filteredData ? filteredData?.sentiment_data['Y-axis'] : cluster_analysis?.sentiment_data['Y-axis']}
            sentimentLabel={sentimentLabel}
            sentimentColor={sentimentColor}
            sentiment={resetFlag === true && filteredData ? filteredData?.sentiment_data.sentiment : cluster_analysis?.sentiment_data.sentiment}
            reviews={resetFlag === true && filteredData ? filteredData?.sentiment_data.reviews : cluster_analysis?.sentiment_data.reviews}
            name={resetFlag === true && filteredData ? filteredData?.sentiment_data.user_name : cluster_analysis?.sentiment_data?.user_name}
            date={resetFlag === true && filteredData ? filteredData?.sentiment_data.date : cluster_analysis?.sentiment_data?.date}
            type={'Sentiment'}
            moduleType='Twitter'
            likes={resetFlag === true && filteredData ? filteredData?.sentiment_data.retweet_count : cluster_analysis?.sentiment_data?.retweet_count}
          /> */}

          <ol>

            {cluster_analysis?.sentiment_data?.circle?.map((i) => (
              <li>
                {i?.summary}
              </li>
            ))
            }
          </ol>

          <Footer>
            This data is observed from {resetFlag ? filteredStartDate : startDate} to {resetFlag ? filteredEndDate : endDate}
          </Footer>
        </Container>
      }
      {/* {

        child?.length > 0 && child[1]?.value &&
        <Container className="pdf-page"  style={{
          pageBreakBefore: 'auto',pageBreakAfter:'auto',pageBreakInside:'avoid'
        }}>
          <Title>Emotions </Title>
          <ClusterAnalysisGraph
            xAxisValues={resetFlag === true && filteredData ? filteredData?.emotion_data['X-axis'] : cluster_analysis?.emotion_data['X-axis']}
            yAxisValues={resetFlag === true && filteredData ? filteredData?.emotion_data['Y-axis'] : cluster_analysis?.emotion_data['Y-axis']}
            sentimentLabel={emotionLabel}
            sentimentColor={emotionColor}
            sentiment={resetFlag === true && filteredData ? filteredData?.emotion_data?.emotion : cluster_analysis?.emotion_data.emotion}
            reviews={resetFlag === true && filteredData ? filteredData?.emotion_data.reviews : cluster_analysis?.emotion_data.reviews}
            name={resetFlag === true && filteredData ? filteredData?.emotion_data.user_name : cluster_analysis?.emotion_data?.user_name}
            date={resetFlag === true && filteredData ? filteredData?.emotion_data.date : cluster_analysis?.emotion_data?.date}
            likes={resetFlag === true && filteredData ? filteredData?.emotion_data.retweet_count : cluster_analysis?.emotion_data?.retweet_count}
            type={'Emotion'}
            moduleType='Twitter'
          />
          <Footer>
            This data is observed from {resetFlag ? filteredStartDate : startDate} to {resetFlag ? filteredEndDate : endDate}
          </Footer>
        </Container>
      } */}

    </Wrapper>
  );
};

export default ClusterAnalysisTab;
